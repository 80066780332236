export class PageInfoModel {
    id;
    title;
    navbar_list;
    constructor(id, title, navbar_list) {
        this.id = id;
        this.title = title;
        this.navbar_list = navbar_list;
    }

}