export class NavbarLinkModel {
    id;
    title;
    page;
    constructor(id, title, page) {
        this.id = id;
        this.title = title;
        this.page = page;
    }

}